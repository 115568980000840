export default {
  heading: 'Vollkorn, serif',
  // heading: 'Handy Casual Condensed, serif',
  body: 'Avenir Next',
  monospace: 'Menlo, monospace',
  display: 'Rae Dunn, san-serif',
  display2: 'Sarina, cursive',
  // display2: 'Rae Dunn Bold, san-serif',
  googleFonts: ['Roboto:100,200,300,400,500,600,700,800,900', 'Vollkorn:400,500,600,700,800,900', 'Sarina'],
  customFamilies: ['Handy Casual Condensed', 'Avenir Next', 'Rae Dunn', 'Rae Dunn Bold'],
  customUrls: [
    'https://www.gonation.biz/fonts/handy-casual-condensed/handy-casual-condensed.css',
    'https://www.gonation.biz/fonts/avenirnext/avenirnext.css',
    'https://gonation.biz/fonts/rae-dunn/rae-dunn.css',
    'https://gonation.biz/fonts/rae-dunn/rae-dunn.css'
  ]
  // customFamilies: [''],
  // customUrls: [''],
}
