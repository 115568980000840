export default {
  // ? ==========================
  // ? =======  Layout  =========
  // ? ==========================

  navigation: {
    '.container': {
      backgroundColor: 'white',
      position: 'static',
      borderBottom: '1px solid lightgrey',
      padding: ['0.5rem', '0.5rem', '1rem', '1rem']
    },
    '.containerScrolled': {
      backgroundColor: 'white',
      borderBottom: '1px solid lightgrey'
    },
    '.smallNavMenu > div': {
      borderColor: 'black'
    },

    '.hamburger > div': {
      backgroundColor: 'black'
    },
    '.navMenuOpen': {
      backgroundColor: 'background',
      backgroundImage:
        "url('https://res.cloudinary.com/gonation/image/upload/v1616701839/sites/mariannas-pantry/repeated.png')",
      backgroundSize: ['cover', '', '', '', '80%'],
      backgroundPosition: 'bottom',
      backgroundRepeat: 'no-repeat'
    },

    navMenuLogo: {
      maxHeight: ['', '', '', '200px']
    },
    '.navItem': {
      color: 'black'
    },
    '.socialIconsContainer, .phoneContainer': {
      color: 'black',
      borderColor: 'black',
      svg: {
        path: {
          fill: 'black'
        }
      }
    },
    '.logo': {
      img: {
        maxHeight: ['100px', '100px'],
        border: 'solid 1px black',
        padding: '0.5rem'
      }
    },
    '.logoScrolled': {
      img: {
        maxHeight: ['65px', '65px'],
        border: 'solid 1px black',
        padding: '0.5rem'
      }
    }
  },

  footer: {
    borderTop: '1px black dashed'
  },

  ctaWidget: {},

  // ? ==========================
  // ? ======  Homepage  ========
  // ? ==========================

  homepageHero: {
    height: ['60vh'],
    justifyContent: 'flex-start',
    alignItems: ['flex-start', '', '', 'center'],
    '.logoHero': {
      display: ['none', '', '', 'block']
    },
    '.slick-slider': {
      width: ['100%', '100%', '100%', '100%'],
      order: ['', '', '', '2'],
      height: ['60vh'],
      '.slick-prev, .slick-next': {
        color: 'white',
        bottom: '0rem',
        backgroundColor: 'primary',
        height: '30px',
        borderRadius: '100px',
        padding: '5px',
        top: 'unset'
      },
      '.slick-prev': {
        left: '1rem'
      },
      '.slick-next': {
        left: '3rem'
      },
      '.slick-dots': {
        left: 'unset',
        right: '1rem',
        flexDirection: 'column',
        width: 'fit-content',
        display: 'flex !important'
      },
      '.slick-dots li button:before': {
        color: 'light'
      },
      '.slick-slide img': {
        height: ['60vh']
      },
      '.slick-slide > div': {
        height: ['60vh']
      }
    },
    '.hero_content_container': {
      // marginBottom: ['', '', '', '7rem'],
      padding: ['2rem'],
      width: ['', '', '', '50%', '40%'],
      '.title': {
        display: 'flex',
        flexDirection: 'column',
        fontSize: ['2.5rem', '', '3.5rem'],
        order: '2',
        color: 'light',
        fontWeight: '500',
        whiteSpace: 'pre-wrap'
      },
      '.subtitle': {
        color: 'light',
        fontWeight: 'bold',
        textTransform: 'initial',
        order: '1',
        fontFamily: 'body',
        fontSize: ['1rem', '', '1.3rem'],
        textTransform: 'uppercase',
        fontFamily: 'body',
        letterSpacing: ['2px', '', '4px']
      },

      '.text': {
        lineHeight: '1.75',
        marginBottom: '1rem',
        order: '3',
        color: 'light'
      },
      a: {
        order: '4',
        alignSelf: 'unset',
        '.ctaButton': {
          variant: 'buttons.primary'
        }
      }
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    '.title': {
      border: ['', '', '', '#ffffff solid 9px']
    },
    '.text': {
      backgroundColor: 'white',
      fontFamily: 'Handy Casual Condensed',
      fontSize: ['1.6rem', '', '2rem']
    },
    '.date': {
      backgroundColor: '#383838',
      border: ['', '', '', '#ffffff solid 9px']
    },
    '.imageContainer': {
      border: ['', '', '', '#ffffff solid 9px']
    },
    borderTop: '2px solid black',
    borderBottom: '2px solid black',
    margin: '0.5rem 0rem'
  },

  homepageAboutSection: {
    margin: '1rem',
    '.content': {
      padding: ['1rem', '2rem', '2rem', '4.5rem', '5.5rem'],
      '.title': {},
      '.subtitle': {
        fontFamily: 'display2',
        color: 'primary',
        fontSize: ['2rem', '', '2.5rem']
      },
      '.text': {},
      a: {}
    },
    '.lazyload-wrapper': {
      maxHeight: ['', '', '', '60vh']
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  homepageMenu: {
    variant: 'customVariants.homepageAboutSection',
    textAlign: 'left',
    '.content': {
      padding: ['1rem', '2rem', '2rem', '4.5rem', '5.5rem'],
      alignItems: 'flex-start',
      '.title': {
        textAlign: 'left'
      },
      '.subtitle': {
        fontFamily: 'display2',
        color: 'primary',
        fontSize: ['2rem', '', '2.5rem'],
        textAlign: 'left'
      },
      '.text': { textAlign: 'left' }
    }
  },

  homepageGallery: {
    variant: 'customVariants.homepageAboutSection'
  },

  homepageBreakfast: {
    padding: ['6rem 1rem 4rem', '8rem 1rem 6rem'],
    margin: ['1.5rem 0rem 1rem', '3rem 0rem 2rem'],
    backgroundImage:
      "url('https://res.cloudinary.com/gonation/image/upload/v1616701839/sites/mariannas-pantry/repeated.png')",
    backgroundPosition: 'center',
    backgroundSize: '440px',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: ['unset', 'unset', 'unset', 'unset']
  },

  homepageContentBoxes: {
    '.image': {
      height: ['250px', '', '350px'],
      objectFit: 'cover'
    },
    '.title': {
      fontWeight: '500',
      marginBottom: '1rem',
      textTransform: 'uppercase'
    }
  },

  // ? ==========================
  // ? =====  About Page  =======
  // ? ==========================
  aboutSection: {
    '.title': {
      fontSize: '2rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    }
  },

  aboutSliderHero: {
    '.title': {
      fontSize: '3rem',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: ['', '', '0rem 2rem 0rem 0rem', '0rem 2rem 0rem 0rem']
    }
  },

  // ? ==========================
  // ? ======  Menu Page  =======
  // ? ==========================

  menu: {
    '.menuSectionTitle': {
      borderTop: '1px black solid',
      borderBottom: '1px black solid',
      padding: '1rem'
    },
    '.allInContainerWrapper': {
      maxWidth: '1200px',
      margin: '0 auto'
    },
    '.menuItemInnerContainer': {
      margin: '0rem'
    },
    '.menuItemImageContainer': {},
    '.menuItemName': {
      margin: '0rem'
    },
    '.menuItemContentContainer': {
      margin: '0rem'
    },
    '.dropdownContainer': {
      display: 'none'
    },
    '.section-cell': {},
    '.cellImageContainer': {},
    '.menuCell, .cellImage': {},
    '.menuCell': {},
    '.cellName': {},
    '.menuItemContainerImgActive': {},
    '.backToMenuBtn': {}
  },

  // ? ==========================
  // ? =====  Gallery Page  =====
  // ? ==========================

  gallery: {
    // '.albumsCTA ': {
    //   variant: 'buttons.secondary',
    // },
    '.albumsContainer': {
      display: 'none'
    },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black'
    }
  },

  locationMap: {
    order: '3',
    h3: {
      textTransform: 'uppercase'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  // ? ==========================
  // ? =====  Events Page  ======
  // ? ==========================

  events: {},

  // ? ==========================
  // ? =====  Contact Page  =====
  // ? ==========================

  contactForm: {
    order: '4',
    h2: {
      textTransform: 'uppercase',
      color: 'black',
      fontSize: '2.5rem'
    },
    backgroundPosition: 'center center',
    color: 'primary',
    padding: ['3rem 1rem', '', '4rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      fontFamily: 'Handy Casual Condensed',
      border: 'none',
      borderBottom: '3px dashed',
      borderColor: 'black',
      borderWidth: '1px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'primary',
      '::placeholder': {
        color: 'grey',
        fontFamily: 'Handy Casual Condensed'
      }
    }
  },

  locationMap: {
    order: '3',
    h3: {
      textTransform: 'uppercase'
    }
  },

  // ? ============================
  // ? =====  Specials Page  ======
  // ? ============================

  specialsMenu: {
    variant: 'customVariants.menu'
  },

  // ? ====================================
  // ? =====  Rotating Specials Page  =====
  // ? ====================================

  rotatingSpecials: {
    variant: 'customVariants.menu'
  }
}
